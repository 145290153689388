import * as Yup from "yup"

const yearNow = new Date(Date.now()).getFullYear()

export const refillFormValidationSchema = Yup.object().shape({
  firstName: Yup.string().required("This field is required."),
  lastName: Yup.string().required("This field is required."),
  emailAddress: Yup.string()
    .email("Please input a valid email")
    .required("This field is required"),
  mobileNumber: Yup.string()
    .min(11, "Please input a valid mobile number in this format: 09991234567")
    .matches(
      /^09[0-9]{9}$/,
      "Please input a valid mobile number in this format: 09991234567"
    )
    .required("This field is required"),
  latestOrderNumber: Yup.string()
    .required("This field is required")
    .max(7, "Please input up to seven characters only"),
  preferredDeliveryDate: Yup.object({
    month: Yup.object().shape({
      value: Yup.string().required("This field is required").nullable(),
    }),
    date: Yup.object().shape({
      value: Yup.number().required("This field is required").nullable(),
    }),
    year: Yup.number()
      .min(yearNow, "Please input a valid year")
      .required("This field is required"),
  }),
  preferredDeliveryTime: Yup.string().required("This field is required"),
  paymentOption: Yup.object().shape({
    value: Yup.string().required("This field is required"),
  }),
})
