import React from "react"
import Layout from "../../../components/Layout/Layout"
import Container from "../../../components/Layout/Container"
import RefillForm from "../../../components/Epharmacy/Refill/RefillForm"

export default () => (
  <Layout
    title="Refill Form"
    seoTitle="Refill Form"
    process="epharmacy"
    subtitle={
      <span>
        Please answer the following questions accurately.
        <br />
        Your information will be used as a basis for your medicine refill.
      </span>
    }
    display={{ footer: false, helpCenterBanner: false }}
  >
    <Container isCentered desktop={6} fullhd={6}>
      <RefillForm backRoute="/" nextRoute="/epharmacy/refill/summary" />
    </Container>
  </Layout>
)
