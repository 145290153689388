import React, { useContext } from "react"
import { Formik, Form } from "formik"

import ActionButtons from "../../Elements/ActionButtons"
import InvalidFormMessage from "../../Elements/InvalidFormMessage"

import { AppContext } from "../../../context/AppContext"
import { refillFormValidationSchema } from "./utils/formData"
import { navigate } from "gatsby"
import { isFutureDate } from "../services/date"
import RefillPrescriptionUpload from "./RefillPrescriptionUpload"
import RefillFormFields from "./RefillFormFields"

const RefillForm = ({ backRoute, nextRoute, type = "RX" }) => {
  const { state, dispatch } = useContext(AppContext)
  const files = state.documents

  const handleOnSubmit = (values, { setErrors }) => {
    let preferredDeliveryDate = values?.preferredDeliveryDate
    preferredDeliveryDate = `${preferredDeliveryDate?.month?.value} ${preferredDeliveryDate?.date?.value} ${preferredDeliveryDate?.year}`

    if (!isFutureDate({ date: preferredDeliveryDate })) {
      setErrors({
        preferredDeliveryDate: {
          month: { value: "Please enter a valid delivery date." },
          date: { value: "Please enter a valid delivery date." },
        },
      })
      return values
    }

    dispatch({
      type: "SAVE_REFILL_FORM",
      payload: values,
    })
    navigate(`${nextRoute}`)
  }

  return (
    <Formik
      initialValues={{ ...state?.epharmacy, paymentOption: { value: "" } }}
      validationSchema={refillFormValidationSchema}
      onSubmit={handleOnSubmit}
    >
      {({ values, isValid, submitCount }) => {
        let preferredDeliveryDate = values?.preferredDeliveryDate
        preferredDeliveryDate = `${preferredDeliveryDate?.month?.value} ${preferredDeliveryDate?.date?.value} ${preferredDeliveryDate?.year}`
        let isInvalidDeliveryDate = !isFutureDate({
          date: preferredDeliveryDate,
        })

        return (
          <Form>
            <RefillFormFields values={values} />
            <RefillPrescriptionUpload
              files={files}
              state={state}
              dispatch={dispatch}
              type={type}
            />

            <div className="mt-3">
              {!isValid && submitCount > 0 && (
                <InvalidFormMessage
                  color="danger"
                  name={
                    isInvalidDeliveryDate
                      ? "invalidDeliveryDate"
                      : "missingRequiredFields"
                  }
                />
              )}
              <ActionButtons
                submit={{ label: "Review Details" }}
                back={{ label: "Back", link: backRoute }}
              />
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}

export default RefillForm
