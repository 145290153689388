import React, { useRef } from "react"
import Dropzone from "../../Upload/Dropzone"
import FilePreviewRow from "../../Upload/FilePreviewRow"
import UploadGuidelines from "../../Upload/UploadGuidelines"
import Section from "../../../components/Elements/Section"

const RefillPrescriptionUpload = ({ files, state, dispatch, type }) => {
  const fileInputRef = useRef(null)
  const MAX_FILE_COUNT = 3
  const MAX_FILE_SIZE_IN_BYTES = 3145728

  const isFileMax = files.length === MAX_FILE_COUNT

  const handleFileChooser = () => {
    fileInputRef.current.click()
  }

  const handleDeleteDocument = async ({ type, index }) => {
    let filesList = [...files].filter((file, fileIndex) => index !== fileIndex)
    let documentsList = [...state.documents].filter(
      (document) => document.type !== type
    )
    await dispatch({
      type: "SAVE_DOCUMENTS",
      payload: [...documentsList, ...filesList],
    })
  }

  return (
    <div className="mt-2">
      <Section
        title={
          <span>
            Upload Prescription{" "}
            <span className="is-italic has-text-grey has-text-weight-normal">
              (Optional)
            </span>
          </span>
        }
      >
        <UploadGuidelines />
        {files?.map((file, index) => (
          <FilePreviewRow
            fileName={file.name}
            oldFileName={file.oldname}
            handleDeleteDocument={() => handleDeleteDocument({ type, index })}
            file={file}
          />
        ))}
        {!isFileMax && (
          <Dropzone
            fileInputRef={fileInputRef}
            handleFileChooser={handleFileChooser}
            maxByteSize={MAX_FILE_SIZE_IN_BYTES}
            maxFileCount={MAX_FILE_COUNT}
            docType="RX"
          />
        )}
      </Section>
    </div>
  )
}

export default RefillPrescriptionUpload
